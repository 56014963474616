<template>
  <v-app id="app">
    <NoJs />
    <v-main>
      <v-container class="h-full">
        <div class="h-full flex-center">
          <div class="md:w-450px w-full">
            <div class="flex-center mb-4">
              <LogoChip size="50" />
            </div>
            <v-card class="mt-5 mb-2 pa-4 md:px-10" rounded="xl">
              <slot></slot>
            </v-card>

            <v-card-actions class="text-3">
              <v-spacer />
              <a class="mr-5" :href="links.privacy" target="_blank">Privacy</a>
              <a :href="links.terms" target="_blank">Terms of Service</a>
            </v-card-actions>
          </div>
        </div>
      </v-container>
    </v-main>
    <!-- <AcceptTerms /> -->
  </v-app>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import AcceptTerms from "~/components/account-alerts/AcceptTerms.vue";

export default defineComponent({
  components: { AcceptTerms },
  setup() {
    const { links } = useAppConfig();

    return {
      links,
    };
  },
});
</script>
